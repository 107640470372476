define("discourse/plugins/discourse-zoom/discourse/initializers/composer-toolbar-webinar-button", ["exports", "discourse/lib/plugin-api", "discourse/lib/show-modal"], function (_exports, _pluginApi, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeWebinarButton(api) {
    api.modifyClass("controller:composer", {
      pluginId: "discourse-zoom",
      actions: {
        showWebinarModal() {
          (0, _showModal.default)("webinar-picker", {
            model: this.model,
            title: "zoom.webinar_picker.title"
          });
        }
      }
    });
    api.addToolbarPopupMenuOptionsCallback(controller => {
      const composer = controller.model;
      if (composer && composer.creatingTopic) {
        return {
          id: "associate_webinar_button",
          icon: "video",
          action: "showWebinarModal",
          label: "zoom.webinar_picker.button"
        };
      }
    });
  }
  var _default = _exports.default = {
    name: "composer-toolbar-webinar-button",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      const currentUser = container.lookup("current-user:main");
      if (siteSettings.zoom_enabled && currentUser) {
        (0, _pluginApi.withPluginApi)("0.5", initializeWebinarButton);
      }
    }
  };
});